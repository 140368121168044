// 用户反馈相关接口
import request from '@/request/request'

// 列表
export const FannieBlogsListApi = (params) => request.post('/fannie/blogs/list', params);
// 详情
export const FannieBlogsDetailApi = (params) => request.get(`/fannie/blogs/details/${params.id}`, params);
// 更新
export const FannieBlogsUpdApi = (params) => request.post('/fannie/blogs/upd', params);
// 新增
export const FannieBlogsAddApi = (params) => request.post('/fannie/blogs/add', params);
// 删除
export const FannieBlogsDelApi = (params) => request.get(`/fannie/blogs/del/${params.id}`, params);
