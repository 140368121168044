<template>
  <div class="home">
    <a-button type="primary" @click="addClick"> 新增 </a-button>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
    >
      <span slot="operation" slot-scope="operation, record">
        <a class="mr20" @click="editClick(record)">编辑</a>
        <a @click="delClick(record)">删除</a>
      </span>
    </a-table>
    <!-- 分页功能 -->
    <MyPagination
      :count="count"
      :pageNo="form.pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import { FannieBlogsListApi, FannieBlogsDelApi } from "@/request/api/home";
import { message, Modal } from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination.vue";
export default {
  components: { MyPagination },
  created() {
    this.FannieBlogsListFn();
  },
  data() {
    return {
      columns: [
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          width: "30%",
          scopedSlots: { customRender: "title" },
        },
        {
          title: "简介",
          dataIndex: "brief",
          key: "brief",
          width: "15%",
          scopedSlots: { customRender: "brief" },
        },
        {
          title: "用户编号",
          dataIndex: "userNo",
          key: "userNo",
          width: "10%",
          scopedSlots: { customRender: "userNo" },
        },
             {
          title: "序号",
          dataIndex: "sort",
          key: "sort",
          width: "5%",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          sorter: true,
          key: "createTime",
          width: "10%",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "更新时间",
          dataIndex: "updateTime",
          width: "10%",
          sorter: true,
          key: "updateTime",
          sorter: (a, b) => {
            let aTime = new Date(a.updateTime).getTime();
            let bTime = new Date(b.updateTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      tableLoading: true,
      form: {
        pageNo: 1,
        pageSize: 20,
        adminFlag:1
      },
      count: 0,
    };
  },
  methods: {
    editClick(record) {
      this.$router.push(`/detail/${record.id}`);
    },
    addClick() {
      this.$router.push(`/detail/0`);
    },
    delClick(record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "是否确认删除",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          FannieBlogsDelApi({ id: record.id }).then(({ code }) => {
            if (code == 200) {
              message.success("删除成功");
              that.FannieBlogsListFn();
            }
          });
        },
      });
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.form.pageNo = current;
      this.form.pageSize = pageSize;
      this.FannieBlogsListFn();
    },
    FannieBlogsListFn() {
      FannieBlogsListApi(this.form).then(({ code, data }) => {
        if (code == 200) {
          this.count = data.count;
          this.tableLoading = false;
          data.data.forEach((item, index) => {
            item.key = index;
          });
          this.tableData = data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
.mr20 {
  margin-right: 20px;
}
</style>
