import Vue from 'vue'
import 'ant-design-vue/dist/antd.css';

import { Button, Spin, Icon, Menu, Layout, Breadcrumb, Input, Tooltip, Table, Tag, Select, Divider, Form, Checkbox, Radio, Modal, Tree, Switch, Upload, FormModel, Tabs, Dropdown, Pagination, DatePicker, message, LocaleProvider, Row, Col, Badge, InputNumber, ConfigProvider, List, Popover, BackTop } from 'ant-design-vue';

Vue.prototype.$message = message;

Vue.use(Button);
Vue.use(Spin);
Vue.use(Icon);
Vue.use(Menu);
Vue.use(Layout);
Vue.use(Breadcrumb);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Tooltip);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Select);
Vue.use(Divider);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Modal);
Vue.use(Tree);
Vue.use(Switch);
Vue.use(Upload);
Vue.use(FormModel);
Vue.use(Tabs);
Vue.use(Dropdown);
Vue.use(Pagination);
Vue.use(DatePicker);
Vue.use(LocaleProvider);
Vue.use(Row);
Vue.use(Col);
Vue.use(Badge);
Vue.use(ConfigProvider);
Vue.use(List);
Vue.use(Popover);
Vue.use(BackTop);